:root {
    /* Font sizes */
    --fontsize-100: clamp(0.5rem, -0.31rem + 3.44vw, 1.5rem);
    --fontsize-200: clamp(0.67rem, -0.4rem + 4.57vw, 2rem);
    --fontsize-300: clamp(0.89rem, -0.53rem + 6.07vw, 2.65rem);
    --fontsize-400: clamp(1.18rem, -0.71rem + 8.07vw, 3.53rem);
    --fontsize-500: clamp(1.58rem, -0.93rem + 10.72vw, 4.69rem);
    --fontsize-600: clamp(2.1rem, -1.23rem + 14.24vw, 6.24rem);
    --fontsize-700: clamp(2.81rem, -1.63rem + 18.92vw, 8.3rem);
    --fontsize-800: clamp(3.74rem, -2.15rem + 25.13vw, 11.04rem);
    --fontsize-900: clamp(4.98rem, -2.84rem + 33.38vw, 14.69rem);

    /* Height for nav bar on small screens */
    --pixels-100: clamp(30.7px, 13.62px + 4.55vw, 48.6px);

    /* Font families */
    --font-family-raleway: 'Raleway', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    --font-family-helvetica: 'Helvetica', serif;
    --font-family-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

    /* Color palette */
    --color-licorice: #1C0C0F;
    --color-palitinate: #5C2562;
    --color-dark-purple: #3f2039;
    --color-chestnut: #924934;
    --color-grey-100: rgb(238, 239, 239);

    --color-palitinate-100: #d8c2db;

}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: var(--color-grey-100);
    font-family: var(--font-family-helvetica);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* background-color: var(--color-licorice); */
}

/* For hamburger menu design see: https://www.youtube.com/watch?v=oLgtucwjVII */

header {
    color: white;
    background-color: var(--color-dark-purple);
}

header nav {
    font-family: var(--font-family-helvetica);
    font-size: var(--fontsize-200);
    /* color: white; */
    /* background-color: rgb(32, 116, 190); */

    display: flex;
    justify-content: space-between;
}

nav label {
    padding-left: 50px;
    margin: auto 0;
}

nav ul {
    display: flex;
    padding-right: 50px;
    list-style: none;
}

nav ul li {
    padding: 10px 20px;
    
}

nav ul li a{
    color: white;
    text-decoration: none;
    text-transform: capitalize;
    border-radius: 3px;
}

a.active, a:hover {
    background-color: var(--color-chestnut);
    padding: 5px 15px;
    transition: .5s;
}

/* Hamburger menu styles for smaller screens */
#check {
    display: none;
}

.check-btn {
    display: none;
}

@media screen and (max-width: 768px) {
    nav ul {
        position: fixed;
        display: block;
        background-color: #2c3e50;
        width: 100%;
        height: 100vh;     /**/
        top: var(--pixels-100);  
        left: -100%;
        text-align: center;
        transition: left 0.5s;
    }
    
    nav ul li {
        display: block;
    }
    
    #check:checked ~ ul{
        left: 0%;
    }

    .check-btn {
        display: flex;
        padding: 10px 20px;
        cursor: pointer;
    }
}

/********************************************************************/
main {
    /* background-color: var(--color-grey-100); */
    /* max-width: 800px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title {
    font-family: var(--font-family-raleway);
    font-size: var(--fontsize-400);
}
section {
    
    color: white;
}

.hero {
    /* See this vid for background-image: https://youtu.be/zHZRFwWQt2w?si=SihQ0fO418gKRkUV; */
    background-color: var(--color-palitinate-100);
    background-image: url('../public/img/poly_picnic.png');
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;

    padding: max(10px, 5vh) max(20px, 10vw);
    
    /* These ones don't work as expected */
    /* background-repeat:no-repeat; */
    /* display: flex;
    flex-direction: column; */
    /* gap: 20px; */
}

.section-title {
    font-family: var(--font-family-raleway);
    font-size: var(--fontsize-500);
    font-weight: 600;
    text-align: center;

    margin-bottom: max(10px, 5vh);
}

.section-text {
    font-family: 'Helvetica', serif;
    font-size: var(--fontsize-200);
    font-weight: 400;
    text-align: justify;
    line-height: 1.3em;

    margin-bottom: max(10px, 5vh);
}

.card-section {
    padding-top: max(10px, 5vh);
}

.card-section h2, .articles-section h2, .contact-section h2 {
    font-family: var(--font-family-raleway);
    font-size: var(--fontsize-400);
    font-weight: 200;
    text-align: center;
    line-height: 1.3em;
    color: #555;

    margin-bottom: 20px;
}

.card-wrapper, .article-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-evenly;
}

.card {
    padding-bottom: max(10px, 5vh);
    width: clamp(280px, 199px + 0.215*100vw, 370px);
}

.card > img {
    width: 100%;
    height:auto;
    margin: 10px;
}

/* .articles-section {
    margin-bottom: 20px;
} */

.articles-wrapper {
    padding: 10px;
}

.card h4, .articles-section h4, .contact-section h4 {
    font-family: var(--font-family-raleway);
    font-size: var(--fontsize-300);
    font-weight: 600;
    text-align: left;
    line-height: 1.1em;
    color: #333;
    padding-bottom: 30px;
    margin-left: 10px;
}

.card p, .articles-section p, .contact-section p {
    font-family: var(--font-family-helvetica);
    font-size: var(--fontsize-200);
    font-weight: 400;
    text-align: justify;
    line-height: 1.1em;
    margin-left: 10px;
}
.articles-section h4, .articles-section p {
    margin-right: 20px;
}

.card a, .articles-section a, .card-section a, main a {
    font-family: var(--font-family-helvetica);
    font-size: var(--fontsize-100);
    font-weight: 400;
    text-align: justify;
    line-height: 1.1em;
    margin-left: 10px;
}

.article img {
    width: 100%;
}

.article h3 {
    font-family: var(--font-family-raleway);
    font-size: var(--fontsize-500);
    font-weight: 200;
    text-align: center;
    line-height: 1.3em;

    margin-bottom: 20px;
}

.date, .author {
    font-family: var(--font-family-helvetica);
    font-size: var(--fontsize-200);
    font-weight: 400;
    text-align: left;
    line-height: 1.1em;
    margin-left: 10px;

}

.author {
    margin-bottom: 20px;
}

.article .body-text p {
    font-family: var(--font-family-helvetica);
    font-size: var(--fontsize-200);
    font-weight: 400;
    text-align: justify;
    line-height: 1.3em;
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 30px;
}

.article-container, .contact-wrapper {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    border-radius: 15px;
    padding: 15px;
    background-color: var(--color-palitinate-100)
}

footer {
    background-color: var(--color-palitinate-100);
}

code {
    font-family: var(--font-family-code);
}
